import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const liminaire = () => (
  <Layout>
    <SEO title="Dernières oeillades - Non!" />
    <h3 className='underline-title'>Non!</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>
        Non!<br />
        Tu n'es pas vrai berger <br />
        Encore moins bon pasteur<br />
        Ton patronyme ne retentit pas d'humilité bergère<br />
        Tes cordes vocales s'ignorent des brebis <br />
        Ta bastonnade gratuite aux dos de celles-ci trahit tes sentiments hypocrites<br />
        Tu ne connais aucune brebis du troupeau <br />
      </p>
      <p>
        Yes!<br />
        Tu conduis les brebis hors pâturage <br />
        Dans des raides et rocailleux ravins sans eau <br />
        Preuve éloquente:<br />
        les brebis ne t'appartiennent pas <br />
        Tu détales dès hurlement lointain d'un louveteau <br />
        En revanche tu pactises avec une meute meurtrière<br />
        Afin de massacre allégrement le troupeau innocent <br />
      </p>
      <p>
        Comme berger tu ressembles au jeune Guillot<br />
        Ce petit berger farceur<br />
      </p>
      <p>
        Non!<br />
        Tu n'es jamais berger ni pasteur <br />
        Tu es un mercenaire roublard <br />
        Pour qui la vie du troupeau ne compte nullement <br />
      </p>
      <p>
        Yes ja oui<br />
        Tu es un loup dans la bergerie<br />
        Rien que méchant loup<br />
        Loup aux crocs acérés<br />
      </p>
      <p>
        Finaud!<br />
      </p>
    </div>
    <br />
    <br />
    <Link to="/dernieres-oeillades-table-des-matieres">Table des matières</Link>
  </Layout>
)

export default liminaire
